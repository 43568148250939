import { endOfDay, format, formatISO, isValid, startOfDay, } from 'date-fns'
import { tz } from '@date-fns/tz'

export enum TimeZoneName {
  /** Alaska Time Zone */
  usAlaska = 'America/Anchorage',
  /** Hawaii-Aleutian Time Zone */
  usHawaii = 'America/Adak',
  /** Pacific Time Zone */
  usPacific = 'America/Los_Angeles',
  /** Mountain Time Zone */
  usMountain = 'America/Denver',
  /** Central Time Zone */
  usCentral = 'America/Chicago',
  /** Eastern Time Zone */
  usEastern = 'America/New_York',
  /** UTC */
  utc = 'UTC',
}

const DATE_ONLY_REG_EXP = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/

export const getSystemTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const localDate = (inputDate: string): Date => {
  // When the time zone offset is absent, date-only forms are interpreted as a UTC time and date-time forms are interpreted as a local time.
  return DATE_ONLY_REG_EXP.test(inputDate)
    ? new Date(`${inputDate}T00:00:00`)
    : new Date(inputDate)
}

export const normalizeDate = (date: string | Date): Date => {
  if (date instanceof Date) {
    return date
  }

  return localDate(date)
}

export const formatDisplayDate = (
  date?: string | Date | null,
  formatPattern = 'MMM dd, yyyy',
) => {
  if (!date || !isValid(new Date(date))) {
    return ''
  }

  return format(normalizeDate(date), formatPattern)
}

/** Returns a Date and time formatted to match: Dec 13, 2023 at 12:01 AM EST */
export const formatDisplayDateTime = (date?: string, timeOnly = false) => {
  if (!date) {
    return ''
  }
  const time = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short',
  }).format(new Date(date))

  if (timeOnly) return time

  return `${formatDisplayDate(date, 'MM/dd/yyyy')} at ${time}`
}

export const stripTimeFromDate = (dateString: string | Date) => {
  if (!dateString) return ''

  if (dateString instanceof Date) {
    return format(dateString, 'yyyy-MM-dd')
  }

  return dateString.split('T')[0]
}

export const getDateRangeDates = (range: [string, string], timezone: TimeZoneName | string = getSystemTimeZone()) => {
    const [start, end] = range.map(localDate)

    return [startOfDay(start, { in: tz(timezone) }), endOfDay(end, { in: tz(timezone) })]
}

export const getDateRangeTimestamps = (range: [string, string], timezone: TimeZoneName | string = getSystemTimeZone(), representation: 'complete' | 'date' = 'complete') => {
  return getDateRangeDates(range, timezone).map(date => formatISO(date, { representation, in: tz('UTC') }))
}
